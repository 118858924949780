import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Carousel, Slide, CarouselNavigationPosition, CarouselPaginationTheme, Section, Colors, ViewAllButton } from '@class101/ui';
import { imageSrc } from './interface';
import { PlayGroundBanner, PlayGroundSectionCarouselContent, SwiperCard, PreloadImage } from '../docsComponents';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "carousel"
    }}>{`Carousel`}</h1>
    <p>{`Swiper를 캡슐화한 컴포넌트 입니다.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Carousel} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Carousel swiperProps={{ freeMode: true }}>\n  {[1, 2, 3].map(order => (\n    <Slide key={`ViewAllButton-${order}`}>\n      <PlayGroundBanner backgroundColor={Colors[`red${order * 100}`]}>\n        배너{order}\n      </PlayGroundBanner>\n    </Slide>\n  ))}\n</Carousel>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Carousel,
      Slide,
      CarouselNavigationPosition,
      CarouselPaginationTheme,
      Section,
      Colors,
      ViewAllButton,
      imageSrc,
      PlayGroundBanner,
      PlayGroundSectionCarouselContent,
      SwiperCard,
      PreloadImage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Carousel swiperProps={{
        freeMode: true
      }} mdxType="Carousel">
    {[1, 2, 3].map(order => <Slide key={`ViewAllButton-${order}`} mdxType="Slide">
        <PlayGroundBanner backgroundColor={Colors[`red${order * 100}`]} mdxType="PlayGroundBanner">
          배너{order}
        </PlayGroundBanner>
      </Slide>)}
  </Carousel>
    </Playground>
    <h2 {...{
      "id": "navigation-position"
    }}>{`Navigation Position`}</h2>
    <p>{`페이지네이션의 위치는 네비게이션 버튼 위치에 따라 정해집니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`enum CarouselNavigationPosition {
  TopRightOut,
  BottomRightOut,
  BottomRightIn,
}
`}</code></pre>
    <h3 {...{
      "id": "toprightout"
    }}>{`TopRightOut`}</h3>
    <p>{`주로 Section에 들어가는 캐러셀일때 사용합니다.`}</p>
    <Playground __position={2} __code={'<Section title=\"섹션 타이틀\">\n  <Carousel\n    navigationPosition={CarouselNavigationPosition.TopRightOut}\n    lgSlidesPerView={4}\n    smSlidesPerView={2}\n    pagination\n  >\n    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(order => (\n      <Slide key={`ViewAllButton-${order}`}>\n        <PlayGroundBanner\n          backgroundColor={Colors[`blue${(((order - 1) % 6) + 1) * 100}`]}\n        >\n          배너{order}\n        </PlayGroundBanner>\n      </Slide>\n    ))}\n  </Carousel>\n</Section>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Carousel,
      Slide,
      CarouselNavigationPosition,
      CarouselPaginationTheme,
      Section,
      Colors,
      ViewAllButton,
      imageSrc,
      PlayGroundBanner,
      PlayGroundSectionCarouselContent,
      SwiperCard,
      PreloadImage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Section title="섹션 타이틀" mdxType="Section">
    <Carousel navigationPosition={CarouselNavigationPosition.TopRightOut} lgSlidesPerView={4} smSlidesPerView={2} pagination mdxType="Carousel">
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(order => <Slide key={`ViewAllButton-${order}`} mdxType="Slide">
          <PlayGroundBanner backgroundColor={Colors[`blue${((order - 1) % 6 + 1) * 100}`]} mdxType="PlayGroundBanner">
            배너{order}
          </PlayGroundBanner>
        </Slide>)}
    </Carousel>
  </Section>
    </Playground>
    <h3 {...{
      "id": "bottomrightout"
    }}>{`BottomRightOut`}</h3>
    <Playground __position={3} __code={'<Carousel\n  navigationPosition={CarouselNavigationPosition.BottomRightOut}\n  lgSlidesPerView={4}\n  smSlidesPerView={2}\n  pagination\n>\n  {[1, 2, 3, 4, 5, 6].map(order => (\n    <Slide key={`ViewAllButton-${order}`}>\n      <PlayGroundBanner backgroundColor={Colors[`blue${order * 100}`]}>\n        배너{order}\n      </PlayGroundBanner>\n    </Slide>\n  ))}\n</Carousel>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Carousel,
      Slide,
      CarouselNavigationPosition,
      CarouselPaginationTheme,
      Section,
      Colors,
      ViewAllButton,
      imageSrc,
      PlayGroundBanner,
      PlayGroundSectionCarouselContent,
      SwiperCard,
      PreloadImage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Carousel navigationPosition={CarouselNavigationPosition.BottomRightOut} lgSlidesPerView={4} smSlidesPerView={2} pagination mdxType="Carousel">
    {[1, 2, 3, 4, 5, 6].map(order => <Slide key={`ViewAllButton-${order}`} mdxType="Slide">
        <PlayGroundBanner backgroundColor={Colors[`blue${order * 100}`]} mdxType="PlayGroundBanner">배너{order}</PlayGroundBanner>
      </Slide>)}
  </Carousel>
    </Playground>
    <h3 {...{
      "id": "bottomrightin"
    }}>{`BottomRightIn`}</h3>
    <Playground __position={4} __code={'<Carousel\n  navigationPosition={CarouselNavigationPosition.BottomRightIn}\n  paginationTheme={CarouselPaginationTheme.Light}\n  pagination\n>\n  {[1, 2, 3, 4, 5, 6].map(order => (\n    <Slide key={`ViewAllButton-${order}`}>\n      <PlayGroundBanner backgroundColor={Colors[`blue${order * 100}`]}>\n        배너{order}\n      </PlayGroundBanner>\n    </Slide>\n  ))}\n</Carousel>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Carousel,
      Slide,
      CarouselNavigationPosition,
      CarouselPaginationTheme,
      Section,
      Colors,
      ViewAllButton,
      imageSrc,
      PlayGroundBanner,
      PlayGroundSectionCarouselContent,
      SwiperCard,
      PreloadImage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Carousel navigationPosition={CarouselNavigationPosition.BottomRightIn} paginationTheme={CarouselPaginationTheme.Light} pagination mdxType="Carousel">
    {[1, 2, 3, 4, 5, 6].map(order => <Slide key={`ViewAllButton-${order}`} mdxType="Slide">
        <PlayGroundBanner backgroundColor={Colors[`blue${order * 100}`]} mdxType="PlayGroundBanner">배너{order}</PlayGroundBanner>
      </Slide>)}
  </Carousel>
    </Playground>
    <h3 {...{
      "id": "navigation-container-max-width"
    }}>{`Navigation Container Max Width`}</h3>
    <Playground __position={5} __code={'<Carousel\n  navigationPosition={CarouselNavigationPosition.BottomRightIn}\n  containerContentMaxWidth={560}\n>\n  <Slide key={`ViewAllButton-1`}>\n    <PlayGroundBanner\n      backgroundColor={Colors.blue100}\n      style={{ display: \'flex\', justifyContent: \'center\' }}\n    >\n      <div\n        style={{\n          width: \'100%\',\n          maxWidth: 560,\n          backgroundColor: \'yellow\',\n          height: 100,\n        }}\n      >\n        배너1\n      </div>\n    </PlayGroundBanner>\n  </Slide>\n  {[2, 3, 4, 5, 6].map(order => (\n    <Slide key={`ViewAllButton-${order}`}>\n      <PlayGroundBanner backgroundColor={Colors[`blue${order * 100}`]}>\n        배너{order}\n      </PlayGroundBanner>\n    </Slide>\n  ))}\n</Carousel>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Carousel,
      Slide,
      CarouselNavigationPosition,
      CarouselPaginationTheme,
      Section,
      Colors,
      ViewAllButton,
      imageSrc,
      PlayGroundBanner,
      PlayGroundSectionCarouselContent,
      SwiperCard,
      PreloadImage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Carousel navigationPosition={CarouselNavigationPosition.BottomRightIn} containerContentMaxWidth={560} mdxType="Carousel">
    <Slide key={`ViewAllButton-1`} mdxType="Slide">
      <PlayGroundBanner backgroundColor={Colors.blue100} style={{
            display: 'flex',
            justifyContent: 'center'
          }} mdxType="PlayGroundBanner">
        <div style={{
              width: '100%',
              maxWidth: 560,
              backgroundColor: 'yellow',
              height: 100
            }}>배너1</div>
      </PlayGroundBanner>
    </Slide>
    {[2, 3, 4, 5, 6].map(order => <Slide key={`ViewAllButton-${order}`} mdxType="Slide">
        <PlayGroundBanner backgroundColor={Colors[`blue${order * 100}`]} mdxType="PlayGroundBanner">배너{order}</PlayGroundBanner>
      </Slide>)}
  </Carousel>
    </Playground>
    <h2 {...{
      "id": "pagination-theme"
    }}>{`Pagination Theme`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`enum CarouselPaginationTheme {
  Dark,
  Light,
}
`}</code></pre>
    <Playground __position={6} __code={'<Carousel\n  navigationPosition={CarouselNavigationPosition.BottomRightIn}\n  paginationTheme={CarouselPaginationTheme.Dark}\n  swiperProps={{ freeMode: false }}\n  pagination\n>\n  {[1, 2, 3].map(order => (\n    <Slide key={`ViewAllButton-${order}`}>\n      <PlayGroundBanner\n        backgroundColor={Colors[`red${order * 100}`]}\n        onClick={() => alert(\'clicked\')}\n      >\n        배너{order}\n      </PlayGroundBanner>\n    </Slide>\n  ))}\n</Carousel>\n<Carousel\n  navigationPosition={CarouselNavigationPosition.BottomRightIn}\n  paginationTheme={CarouselPaginationTheme.Light}\n  swiperProps={{ freeMode: false }}\n  pagination\n>\n  {[1, 2, 3].map(order => (\n    <Slide key={`ViewAllButton-${order}`}>\n      <PlayGroundBanner backgroundColor={Colors[`blue${order * 100}`]}>\n        배너{order}\n      </PlayGroundBanner>\n    </Slide>\n  ))}\n</Carousel>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Carousel,
      Slide,
      CarouselNavigationPosition,
      CarouselPaginationTheme,
      Section,
      Colors,
      ViewAllButton,
      imageSrc,
      PlayGroundBanner,
      PlayGroundSectionCarouselContent,
      SwiperCard,
      PreloadImage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Carousel navigationPosition={CarouselNavigationPosition.BottomRightIn} paginationTheme={CarouselPaginationTheme.Dark} swiperProps={{
        freeMode: false
      }} pagination mdxType="Carousel">
    {[1, 2, 3].map(order => <Slide key={`ViewAllButton-${order}`} mdxType="Slide">
        <PlayGroundBanner backgroundColor={Colors[`red${order * 100}`]} onClick={() => alert('clicked')} mdxType="PlayGroundBanner">
          배너{order}
        </PlayGroundBanner>
      </Slide>)}
  </Carousel>
  <Carousel navigationPosition={CarouselNavigationPosition.BottomRightIn} paginationTheme={CarouselPaginationTheme.Light} swiperProps={{
        freeMode: false
      }} pagination mdxType="Carousel">
    {[1, 2, 3].map(order => <Slide key={`ViewAllButton-${order}`} mdxType="Slide">
        <PlayGroundBanner backgroundColor={Colors[`blue${order * 100}`]} mdxType="PlayGroundBanner">배너{order}</PlayGroundBanner>
      </Slide>)}
  </Carousel>
    </Playground>
    <h2 {...{
      "id": "loop"
    }}>{`Loop`}</h2>
    <Playground __position={7} __code={'<Carousel\n  navigationPosition={CarouselNavigationPosition.BottomRightIn}\n  paginationTheme={CarouselPaginationTheme.Light}\n  swiperProps={{ freeMode: true, loop: true }}\n  pagination\n>\n  {[1, 2, 3].map(order => (\n    <Slide key={`ViewAllButton-${order}`}>\n      <PlayGroundBanner backgroundColor={Colors[`blue${order * 100}`]}>\n        배너{order}\n      </PlayGroundBanner>\n    </Slide>\n  ))}\n</Carousel>\n<Carousel\n  navigationPosition={CarouselNavigationPosition.BottomRightIn}\n  paginationTheme={CarouselPaginationTheme.Light}\n  swiperProps={{ freeMode: false, loop: true }}\n  pagination\n>\n  {[1, 2, 3].map(order => (\n    <Slide key={`ViewAllButton-${order}`}>\n      <PlayGroundBanner backgroundColor={Colors[`blue${order * 100}`]}>\n        배너{order}\n      </PlayGroundBanner>\n    </Slide>\n  ))}\n</Carousel>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Carousel,
      Slide,
      CarouselNavigationPosition,
      CarouselPaginationTheme,
      Section,
      Colors,
      ViewAllButton,
      imageSrc,
      PlayGroundBanner,
      PlayGroundSectionCarouselContent,
      SwiperCard,
      PreloadImage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Carousel navigationPosition={CarouselNavigationPosition.BottomRightIn} paginationTheme={CarouselPaginationTheme.Light} swiperProps={{
        freeMode: true,
        loop: true
      }} pagination mdxType="Carousel">
    {[1, 2, 3].map(order => <Slide key={`ViewAllButton-${order}`} mdxType="Slide">
        <PlayGroundBanner backgroundColor={Colors[`blue${order * 100}`]} mdxType="PlayGroundBanner">배너{order}</PlayGroundBanner>
      </Slide>)}
  </Carousel>
  <Carousel navigationPosition={CarouselNavigationPosition.BottomRightIn} paginationTheme={CarouselPaginationTheme.Light} swiperProps={{
        freeMode: false,
        loop: true
      }} pagination mdxType="Carousel">
    {[1, 2, 3].map(order => <Slide key={`ViewAllButton-${order}`} mdxType="Slide">
        <PlayGroundBanner backgroundColor={Colors[`blue${order * 100}`]} mdxType="PlayGroundBanner">배너{order}</PlayGroundBanner>
      </Slide>)}
  </Carousel>
    </Playground>
    <h2 {...{
      "id": "autoplay"
    }}>{`Autoplay`}</h2>
    <p><a parentName="p" {...{
        "href": "https://idangero.us/swiper/api/#autoplay"
      }}>{`swiper api: Autoplay`}</a></p>
    <Playground __position={8} __code={'<Carousel\n  navigationPosition={CarouselNavigationPosition.BottomRightIn}\n  paginationTheme={CarouselPaginationTheme.Light}\n  swiperProps={{\n    autoplay: {\n      delay: 6000,\n      disableOnInteraction: false,\n    },\n  }}\n  pagination\n>\n  {[1, 2, 3].map(order => (\n    <Slide key={`ViewAllButton-${order}`}>\n      <PlayGroundBanner backgroundColor={Colors[`blue${order * 100}`]}>\n        배너{order}\n      </PlayGroundBanner>\n    </Slide>\n  ))}\n</Carousel>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Carousel,
      Slide,
      CarouselNavigationPosition,
      CarouselPaginationTheme,
      Section,
      Colors,
      ViewAllButton,
      imageSrc,
      PlayGroundBanner,
      PlayGroundSectionCarouselContent,
      SwiperCard,
      PreloadImage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Carousel navigationPosition={CarouselNavigationPosition.BottomRightIn} paginationTheme={CarouselPaginationTheme.Light} swiperProps={{
        autoplay: {
          delay: 6000,
          disableOnInteraction: false
        }
      }} pagination mdxType="Carousel">
    {[1, 2, 3].map(order => <Slide key={`ViewAllButton-${order}`} mdxType="Slide">
        <PlayGroundBanner backgroundColor={Colors[`blue${order * 100}`]} mdxType="PlayGroundBanner">배너{order}</PlayGroundBanner>
      </Slide>)}
  </Carousel>
    </Playground>
    <h2 {...{
      "id": "slide-to-clicked-slide"
    }}>{`Slide To Clicked Slide`}</h2>
    <Playground __position={9} __code={'<Carousel\n  navigationPosition={CarouselNavigationPosition.BottomRightIn}\n  paginationTheme={CarouselPaginationTheme.Light}\n  lgSlidesPerView={4}\n  smSlidesPerView={2}\n  smSlidesSideOffset={24}\n  swiperProps={{\n    slideToClickedSlide: true,\n  }}\n  slideToClickedSlide\n  pagination\n>\n  {[1, 2, 3, 4, 5, 6, 7].map(order => (\n    <Slide key={`ViewAllButton-${order}`}>\n      <PlayGroundBanner backgroundColor={Colors[`blue${order * 100}`]}>\n        배너{order}\n      </PlayGroundBanner>\n    </Slide>\n  ))}\n</Carousel>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Carousel,
      Slide,
      CarouselNavigationPosition,
      CarouselPaginationTheme,
      Section,
      Colors,
      ViewAllButton,
      imageSrc,
      PlayGroundBanner,
      PlayGroundSectionCarouselContent,
      SwiperCard,
      PreloadImage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Carousel navigationPosition={CarouselNavigationPosition.BottomRightIn} paginationTheme={CarouselPaginationTheme.Light} lgSlidesPerView={4} smSlidesPerView={2} smSlidesSideOffset={24} swiperProps={{
        slideToClickedSlide: true
      }} slideToClickedSlide pagination mdxType="Carousel">
    {[1, 2, 3, 4, 5, 6, 7].map(order => <Slide key={`ViewAllButton-${order}`} mdxType="Slide">
        <PlayGroundBanner backgroundColor={Colors[`blue${order * 100}`]} mdxType="PlayGroundBanner">배너{order}</PlayGroundBanner>
      </Slide>)}
  </Carousel>
    </Playground>
    <h2 {...{
      "id": "viewallbutton"
    }}>{`ViewAllButton`}</h2>
    <Playground __position={10} __code={'<Section title=\"섹션 타이틀\">\n  <Carousel\n    navigationPosition={CarouselNavigationPosition.TopRightOut}\n    lgSlidesPerView={4}\n    smSlidesPerView={2}\n    pagination\n  >\n    {[1, 2, 3, 4, 5, 6].map(order => (\n      <Slide key={`ViewAllButton-${order}`}>\n        <PlayGroundBanner backgroundColor={Colors[`blue${order * 100}`]}>\n          배너{order}\n        </PlayGroundBanner>\n      </Slide>\n    ))}\n    <Slide>\n      <ViewAllButton />\n    </Slide>\n  </Carousel>\n</Section>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Carousel,
      Slide,
      CarouselNavigationPosition,
      CarouselPaginationTheme,
      Section,
      Colors,
      ViewAllButton,
      imageSrc,
      PlayGroundBanner,
      PlayGroundSectionCarouselContent,
      SwiperCard,
      PreloadImage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Section title="섹션 타이틀" mdxType="Section">
    <Carousel navigationPosition={CarouselNavigationPosition.TopRightOut} lgSlidesPerView={4} smSlidesPerView={2} pagination mdxType="Carousel">
      {[1, 2, 3, 4, 5, 6].map(order => <Slide key={`ViewAllButton-${order}`} mdxType="Slide">
          <PlayGroundBanner backgroundColor={Colors[`blue${order * 100}`]} mdxType="PlayGroundBanner">배너{order}</PlayGroundBanner>
        </Slide>)}
      <Slide mdxType="Slide">
        <ViewAllButton mdxType="ViewAllButton" />
      </Slide>
    </Carousel>
  </Section>
    </Playground>
    <h2 {...{
      "id": "slides-side-offset"
    }}>{`Slides Side Offset`}</h2>
    <p>{`1024이하에서 확인하세요.
섹션 콘텐츠를 감싸는 `}<inlineCode parentName="p">{`PlayGroundSectionCarouselContent`}</inlineCode>{`에 양옆으로 -24px 음수 마진이 적용되어 있습니다.`}</p>
    <Playground __position={11} __code={'<Section title=\"섹션 타이틀\">\n  <PlayGroundSectionCarouselContent>\n    <Carousel\n      navigationPosition={CarouselNavigationPosition.TopRightOut}\n      lgSlidesPerView={4}\n      smSlidesPerView={2}\n      smSlidesSideOffset={24}\n      pagination\n    >\n      {[1, 2, 3, 4, 5, 6].map(order => (\n        <Slide key={`ViewAllButton-${order}`}>\n          <PlayGroundBanner backgroundColor={Colors[`blue${order * 100}`]}>\n            배너{order}\n          </PlayGroundBanner>\n        </Slide>\n      ))}\n    </Carousel>\n  </PlayGroundSectionCarouselContent>\n</Section>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Carousel,
      Slide,
      CarouselNavigationPosition,
      CarouselPaginationTheme,
      Section,
      Colors,
      ViewAllButton,
      imageSrc,
      PlayGroundBanner,
      PlayGroundSectionCarouselContent,
      SwiperCard,
      PreloadImage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Section title="섹션 타이틀" mdxType="Section">
    <PlayGroundSectionCarouselContent mdxType="PlayGroundSectionCarouselContent">
      <Carousel navigationPosition={CarouselNavigationPosition.TopRightOut} lgSlidesPerView={4} smSlidesPerView={2} smSlidesSideOffset={24} pagination mdxType="Carousel">
        {[1, 2, 3, 4, 5, 6].map(order => <Slide key={`ViewAllButton-${order}`} mdxType="Slide">
            <PlayGroundBanner backgroundColor={Colors[`blue${order * 100}`]} mdxType="PlayGroundBanner">배너{order}</PlayGroundBanner>
          </Slide>)}
      </Carousel>
    </PlayGroundSectionCarouselContent>
  </Section>
    </Playground>
    <h2 {...{
      "id": "slides-between"
    }}>{`Slides Between`}</h2>
    <p>{`slidesPerView 값이 1일 경우 기본적으로 spaceBetween 값은 0입니다.`}</p>
    <Playground __position={12} __code={'<Carousel\n  lgSpaceBetween\n  lgSlidesSideOffset={32}\n  smSpaceBetween\n  smSlidesSideOffset={24}\n>\n  {[1, 2, 3].map(order => (\n    <Slide key={`ViewAllButton-${order}`}>\n      <PlayGroundBanner backgroundColor={Colors[`red${order * 100}`]}>\n        배너{order}\n      </PlayGroundBanner>\n    </Slide>\n  ))}\n</Carousel>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Carousel,
      Slide,
      CarouselNavigationPosition,
      CarouselPaginationTheme,
      Section,
      Colors,
      ViewAllButton,
      imageSrc,
      PlayGroundBanner,
      PlayGroundSectionCarouselContent,
      SwiperCard,
      PreloadImage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Carousel lgSpaceBetween lgSlidesSideOffset={32} smSpaceBetween smSlidesSideOffset={24} mdxType="Carousel">
    {[1, 2, 3].map(order => <Slide key={`ViewAllButton-${order}`} mdxType="Slide">
        <PlayGroundBanner backgroundColor={Colors[`red${order * 100}`]} mdxType="PlayGroundBanner">배너{order}</PlayGroundBanner>
      </Slide>)}
  </Carousel>
    </Playground>
    <h2 {...{
      "id": "external-operation"
    }}>{`External operation`}</h2>
    <p><inlineCode parentName="p">{`onChangeSlide`}</inlineCode>{`과 `}<inlineCode parentName="p">{`activeIndex`}</inlineCode>{`로 외부에서 캐러셀을 조작할 수 있습니다.`}</p>
    <Playground __position={13} __code={'() => {\n  const [currentIndex, setCurrentIndex] = React.useState(0)\n  return (\n    <div>\n      <Carousel onChangeSlide={setCurrentIndex} activeIndex={currentIndex}>\n        {[1, 2, 3].map(order => (\n          <Slide key={`ViewAllButton-${order}`}>\n            <PlayGroundBanner backgroundColor={Colors[`red${order * 100}`]}>\n              배너{order}\n            </PlayGroundBanner>\n          </Slide>\n        ))}\n      </Carousel>\n      <div>\n        <button onClick={() => setCurrentIndex(0)}>\n          1 {currentIndex === 0 && \'active!!\'}\n        </button>\n        <button onClick={() => setCurrentIndex(1)}>\n          2 {currentIndex === 1 && \'active!!\'}\n        </button>\n        <button onClick={() => setCurrentIndex(2)}>\n          3 {currentIndex === 2 && \'active!!\'}\n        </button>\n      </div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Carousel,
      Slide,
      CarouselNavigationPosition,
      CarouselPaginationTheme,
      Section,
      Colors,
      ViewAllButton,
      imageSrc,
      PlayGroundBanner,
      PlayGroundSectionCarouselContent,
      SwiperCard,
      PreloadImage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [currentIndex, setCurrentIndex] = React.useState(0);
        return <div>
        <Carousel onChangeSlide={setCurrentIndex} activeIndex={currentIndex} mdxType="Carousel">
          {[1, 2, 3].map(order => <Slide key={`ViewAllButton-${order}`} mdxType="Slide">
              <PlayGroundBanner backgroundColor={Colors[`red${order * 100}`]} mdxType="PlayGroundBanner">배너{order}</PlayGroundBanner>
            </Slide>)}
        </Carousel>
        <div>
          <button onClick={() => setCurrentIndex(0)}>1 {currentIndex === 0 && 'active!!'}</button>
          <button onClick={() => setCurrentIndex(1)}>2 {currentIndex === 1 && 'active!!'}</button>
          <button onClick={() => setCurrentIndex(2)}>3 {currentIndex === 2 && 'active!!'}</button>
        </div>
      </div>;
      }}
    </Playground>
    <p>{`loop 모드`}</p>
    <Playground __position={14} __code={'() => {\n  const [currentIndex, setCurrentIndex] = React.useState(0)\n  return (\n    <div>\n      <Carousel activeIndex={currentIndex} swiperProps={{ loop: true }}>\n        {[1, 2, 3].map(order => (\n          <Slide key={`ViewAllButton-${order}`}>\n            <PlayGroundBanner backgroundColor={Colors[`red${order * 100}`]}>\n              배너{order}\n            </PlayGroundBanner>\n          </Slide>\n        ))}\n      </Carousel>\n      <div>\n        <button onClick={() => setCurrentIndex(0)}>\n          1 {currentIndex === 0 && \'active!!\'}\n        </button>\n        <button onClick={() => setCurrentIndex(1)}>\n          2 {currentIndex === 1 && \'active!!\'}\n        </button>\n        <button onClick={() => setCurrentIndex(2)}>\n          3 {currentIndex === 2 && \'active!!\'}\n        </button>\n      </div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Carousel,
      Slide,
      CarouselNavigationPosition,
      CarouselPaginationTheme,
      Section,
      Colors,
      ViewAllButton,
      imageSrc,
      PlayGroundBanner,
      PlayGroundSectionCarouselContent,
      SwiperCard,
      PreloadImage,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [currentIndex, setCurrentIndex] = React.useState(0);
        return <div>
        <Carousel activeIndex={currentIndex} swiperProps={{
            loop: true
          }} mdxType="Carousel">
          {[1, 2, 3].map(order => <Slide key={`ViewAllButton-${order}`} mdxType="Slide">
              <PlayGroundBanner backgroundColor={Colors[`red${order * 100}`]} mdxType="PlayGroundBanner">배너{order}</PlayGroundBanner>
            </Slide>)}
        </Carousel>
        <div>
          <button onClick={() => setCurrentIndex(0)}>1 {currentIndex === 0 && 'active!!'}</button>
          <button onClick={() => setCurrentIndex(1)}>2 {currentIndex === 1 && 'active!!'}</button>
          <button onClick={() => setCurrentIndex(2)}>3 {currentIndex === 2 && 'active!!'}</button>
        </div>
      </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      